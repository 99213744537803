<template>
  <div class="bodys">
    <div class="cont">
      <div class="cont-right">
        <div class="cont-center">
          <div class="cont_tab">
            <div :class="tab == '0' ? 'tab tabs' : 'tab'" @click="tabs('0')">
              全部
              <div class="tabj" v-show="tab == '0'"></div>
            </div>
            <div :class="tab == 1 ? 'tab tabs' : 'tab'" @click="tabs('1')">
              邀请中
              <div class="tabj" v-show="tab == 1"></div>
            </div>
            <div :class="tab == 4 ? 'tab tabs' : 'tab'" @click="tabs('4')">
              定制失败
              <div class="tabj" v-show="tab == 4"></div>
            </div>
            <div :class="tab == 2 ? 'tab tabs' : 'tab'" @click="tabs('2')">
              操作中
              <div class="tabj" v-show="tab == 2"></div>
            </div>
            <div :class="tab == 3 ? 'tab tabs' : 'tab'" @click="tabs('3')">
              已清盘
              <div class="tabj" v-show="tab == 3"></div>
            </div>
          </div>
        </div>
        <div class="tab_heng"></div>
        <div
          class="cont-center"
          style="padding-bottom: 20px; overflow: auto"
          v-infinite-scroll="load"
          :infinite-scroll-immediate="false"
        >
          <!-- 全部 未开始 进行中 已结束 根据判断 status 的值显示 -->
          <div class="tab_list" v-for="(item, index) in list" :key="index">
            <div class="list_cont">
              <div class="list_top">
                <div class="list_num">{{ item.num }}</div>
                <div class="list_felx">
                  <div
                    class="sec"
                    v-show="item.status == 0 || item.status == 1"
                  >
                    邀请倒计时:
                    <van-count-down :time="item.exp_at * 1000 - date" />
                  </div>
                  <div class="list_status1">
                    {{ item.flag }}
                  </div>
                </div>
              </div>
              <div class="list">
                <div class="list_name">{{ item.title }}</div>
                <div class="list_zhi">{{ (item.amount / 100).toFixed(2) }}</div>
              </div>
              <div class="list">
                <div class="list_admin">
                  <div class="list_xiang">
                    <i v-if="item.flag == '被邀请中'">预付年化收益率:</i>
                    <i v-else>预期年化收益率:</i>
                    <span
                      >{{ item.annual_profit / 100 + "%" }}+{{
                        item.float_profit / 100
                      }}%</span
                    >
                  </div>
                  <div class="list_xiang">
                    周期: <span>{{ item.days }}天</span>
                  </div>
                  <div class="list_xiang">
                    每日收益:
                    <span>{{ (item.profit / 100).toFixed(2) }}元</span>
                  </div>
                </div>
                <div class="list_admin">
                  <div class="list_xiang">
                    最大风险:
                    <span v-if="item.flag == '被邀请中'"
                      >{{ item.risk_fund_rate / 100 }}%</span
                    >
                    <span v-else>0%</span>
                  </div>
                  <div class="list_xiang">
                    <i v-if="item.flag == '被邀请中'">预付总收益:</i>
                    <i v-else>到期总收益:</i>
                    
                    <span v-if="item.flag == '已清盘'">{{item.all_profit / 100}}元</span>
                    <span v-else>{{
                        item.operator_annual_profit / 100
                      }}元</span>
                  </div>
                  <div class="list_xiang">
                    到期时间:
                    <span class="redcolor">{{
                      (item.end_at * 1000) | format("YYYY-MM-DD HH:mm")
                    }}</span>
                  </div>
                </div>
                <div class="list_admin2">
                  <div
                    class="list_btn"
                    v-show="
                      item.flag == '邀请中' ||
                      item.flag == '被邀请中' ||
                      item.flag == '定制失败'
                    "
                    @click="xiang(item.id, item.flag)"
                  >
                    查看详情
                  </div>
                  <div
                    class="list_btn2"
                    v-show="item.flag == '操作中' || item.flag == '已清盘'"
                    @click="jilu(item.id, item.flag)"
                  >
                    查看记录
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 遮罩层  -->
    <div class="selected_zhe" v-show="selected_zhe"></div>
    <!-- 定制信息 确定 -->
    <div class="custom" v-show="custom">
      <div class="custom_center">
        <div class="custom_title">定制信息</div>
        <div class="custom_list">
          <div class="custom_name">定制名称:</div>
          <div class="custom_inp">{{ info.title }}</div>
        </div>
        <div class="custom_list">
          <div class="custom_name">定制金额(元):</div>
          <div class="custom_inp custom_red">
            {{ (info.amount / 100).toFixed(2) }}
          </div>
        </div>
        <div class="custom_list">
          <div class="custom_name">定制周期(天):</div>
          <div class="custom_inp custom_red">{{ info.days }}</div>
        </div>
        <div class="custom_list">
          <div class="custom_name">到期时间:</div>
          <div class="custom_inp">
            {{ (info.end_at * 1000) | format("YYYY-MM-DD") }}
          </div>
        </div>
        <div class="custom_list">
          <div class="custom_name">预期年化收益率:</div>
          <div class="custom_inp">
            {{ info.annual_profit / 100 }}%+{{ info.float_profit / 100 }}%
          </div>
        </div>
        <div class="custom_list">
          <div class="custom_name">投资者到期收益(元):</div>
          <div class="custom_inp custom_red">
            {{ (info.all_profit / 100).toFixed(2) }}
          </div>
        </div>
        <div class="custom_heng"></div>
        <div class="investment">
          <div class="investment_one">定制各方预计投入金额(元):</div>
          <div class="investment_two">
            <div class="two_left">{{ info.investor }}:</div>
            <div class="two_right">
              {{ (info.amount / 100).toFixed(2) }}(投资金)
            </div>
          </div>
          <div class="investment_two">
            <div class="two_left">{{ info.operator }}:</div>
            <div class="two_right">
              {{ (info.operator_risk_fund / 100).toFixed(2) }}(风险金)
            </div>
          </div>
          <div class="investment_two">
            <div class="two_left"></div>
            <div class="two_right">
              {{ (info.operator_annual_profit / 100).toFixed(2) }}(预付收益)
            </div>
          </div>
        </div>
        <div class="determine" @click="determine">确定</div>
      </div>
      <img
        src="../../../../assets/img/close2.png"
        class="close2"
        @click="close"
        alt=""
      />
    </div>
    <!-- 定制信息 接受 -->
    <div class="custom" v-show="custom_accept">
      <div class="custom_center">
        <div class="custom_title">定制信息</div>
        <div class="custom_list">
          <div class="custom_name">定制名称:</div>
          <div class="custom_inp">{{ info.title }}</div>
        </div>
        <div class="custom_list">
          <div class="custom_name">定制金额(元):</div>
          <div class="custom_inp custom_red">
            {{ (info.amount / 100).toFixed(2) }}
          </div>
        </div>
        <div class="custom_list">
          <div class="custom_name">定制周期(天):</div>
          <div class="custom_inp custom_red">{{ info.days }}</div>
        </div>
        <div class="custom_list">
          <div class="custom_name">到期时间:</div>
          <div class="custom_inp">
            {{ (info.end_at * 1000) | format("YYYY-MM-DD") }}
          </div>
        </div>
        <div class="custom_list">
          <div class="custom_name">预期年化收益率:</div>
          <div class="custom_inp">
            {{ info.annual_profit / 100 }}%+{{ info.float_profit / 100 }}%
          </div>
        </div>
        <div class="custom_list">
          <div class="custom_name">投资者到期收益(元):</div>
          <div class="custom_inp custom_red">
            {{ (info.operator_annual_profit / 100).toFixed(2) }}
          </div>
        </div>
        <div class="custom_heng"></div>
        <div class="investment">
          <div class="investment_one">定制各方预计投入金额(元):</div>
          <div class="investment_two">
            <div class="two_left">{{ info.investor }}:</div>
            <div class="two_right">
              {{ (info.amount / 100).toFixed(2) }}(投资金)
            </div>
          </div>
          <div class="investment_two">
            <div class="two_left">{{ info.operator }}:</div>
            <div class="two_right">
              {{ (info.operator_risk_fund / 100).toFixed(2) }}(风险金)
            </div>
          </div>
          <div class="investment_two">
            <div class="two_left"></div>
            <div class="two_right">
              {{ (info.operator_annual_profit / 100).toFixed(2) }}(预付收益)
            </div>
          </div>
        </div>
        <div class="determine" @click="accept">接受</div>
      </div>
      <img
        src="../../../../assets/img/close2.png"
        class="close2"
        @click="close"
        alt=""
      />
    </div>
    <!-- 资金密码 -->
    <div class="capital" v-show="capital">
      <div class="fundPassword_title">资金密码</div>
      <div class="fundPassword_list">
        <div class="fundPassword_inp">资金密码：</div>
        <input
          class="fundPassword_pwd"
          type="password"
          v-model="password"
          placeholder="请输入资金密码"
          autocomplete="off"
        />
      </div>
      <router-link to="/vipCenter/changeMoneyPwd" class="fundPassword_wang">
        <img
          class="fundPassword_tu"
          src="../../../../assets/img/key2.png"
          alt=""
        />
        <div class="fundPassword_tiao">忘记密码</div>
      </router-link>
      <div class="fundPassword_bottom">
        <div class="fundPassword_no" @click="capital_no">取消</div>
        <div class="fundPassword_ok" @click="capital_ok">确定</div>
      </div>
    </div>
    <!-- 接受成功 弹窗 -->
    <div class="accept" v-show="accept_tan_suc">
      <div class="tishi">
        <p>接受成功，定制已成立，</p>
        <p>交易账号以短信发送，注意查收</p>
      </div>
      <button class="zhidao" @click="zhidao">我知道了</button>
    </div>
    <!-- 接受失败 弹窗  -->
    <div class="accept_fail" v-show="accept_tan_fail">
      <div class="fail-content">
        <div class="fail_title">接受失败</div>
        <img src="../../../../assets/img/redlose.png" class="redclose" alt="" />
        <div class="fail_buzu">{{ fail_reason }}</div>
      </div>
      <img
        src="../../../../assets/img/close2.png"
        class="close2"
        @click="close2"
        alt=""
      />
      <!-- <button class="fail_up" @click="$router.push('#')" style="display: none">
        去充值
      </button> -->
    </div>
  </div>
</template>

<script>
import { myBespoke, confirmBespoke } from "@network/mine";
export default {
  name: "Mycustom",
  data() {
    return {
      date: new Date(),
      tab: 0, //tab切换
      status: "", //进行状态  0 未开始 1 进行中 2 结束
      list: [],
      custom: false, //定制信息的弹窗
      custom_accept: false, //定制信息的弹窗 接受
      capital: false, //资金密码的弹窗
      valueMoney: "", //定制金额
      password: "", //资金密码
      selected_zhe: false, //遮罩层
      accept_tan_suc: false, //接受成功 弹窗
      accept_tan_fail: false, //接受失败 弹窗
      page: "1",
      limit: "5",
      id: "",
      info: {}, //展示数据
      fail_reason: "",
    };
  },
  created() {},
  components: {},
  methods: {
    load() {
      this.myBespoke();
      this.page++;
    },
    close2() {
      this.accept_tan_fail = false;
      this.selected_zhe = false;
    },
    myBespoke() {
      myBespoke(this.page, this.limit, this.status).then((res) => {
        console.log(res);
        if (res.code != 0) {
          if (res.code == 403) {
            this.$storage.remove("token");
            this.$message.error({ message: "请重新登录", offset: 300 });
            this.$router.replace("/home");
            return false;
          }
          this.$message.error({
            message: "获取失败",
            offset: 300,
          });
          return false;
        } else {
          this.count = res.count;
          this.list.push(...res.data);
          // // 数据全部加载完成
          // if (this.record.length >= res.count) {
          //   this.finished = true;
          // }
        }
      });
    },
    // 查看详情
    xiang(id, status) {
      console.log(status);
      this.id = id;
      this.list.forEach((item) => {
        if (item.id == id) {
          this.info = item;
        }
      });
      this.selected_zhe = true;
      if (status == "被邀请中") {
        this.custom_accept = true;
      } else {
        this.custom = true;
      }
    },
    // 查看记录
    jilu(id) {
      this.id = id;
      this.list.forEach((item) => {
        if (item.id == id) {
          this.info = item;
        }
      });
      this.custom = true;
      this.selected_zhe = true;
    },
    //资金密码
    capital_no() {
      this.capital = false;
      this.selected_zhe = false;
    },
    capital_ok() {
      if (this.password == "") {
        this.$message.error({
          message: "请输入密码",
          offset: 300,
        });
        return false;
      }
      confirmBespoke(this.info.id, this.password).then((res) => {
        console.log(res);
        if (res.code != 0) {
          this.fail_reason = res.msg;
          this.accept_tan_fail = true;
        } else {
          this.accept_tan_suc = true;
        }
      });
      this.capital = false;
    },
    // 知道了
    zhidao() {
      this.selected_zhe = false;
      this.accept_tan_suc = false;
    },
    // 定制信息 确定
    determine() {
      this.custom = false;
      this.selected_zhe = false;
    },
    // 定制信息 接受
    accept() {
      this.custom_accept = false;
      this.capital = true;
    },
    close() {
      this.custom_accept = false;
      this.custom = false;
      this.selected_zhe = false;
    },
    tabs(name) {
      if (name == "0") {
        this.status = "";
        this.tab = "0";
      } else if (name == "1") {
        this.status = "1";
        this.tab = "1";
      } else if (name == "2") {
        this.status = "2";
        this.tab = "2";
      } else if (name == "3") {
        this.status = "3";
        this.tab = "3";
      } else if (name == "4") {
        this.status = "4";
        this.tab = "4";
      }

      this.page = "1";
      this.list = [];
      this.load();
    },
  },
};
</script>
<style lang='less' scoped>
@import "../../../../assets/css/vipCenter/ChildComps/myjoin.less";
</style>
